<template>
  <div class="container">
    <div class="card" :style="{'background-image':'url('+info.fee_cover+')'}">
      <div class="card-p">{{ info.fee_name }}</div>
      <div class="card-p">
        <span>{{ info.fee_type }}</span>
        <span>￥{{ info.price }}</span>
      </div>
    </div>

    <div class="cell">
      <div class="cell-hd">
        <div class="cell-hd-title">上课校区</div>
      </div>
      <div class="cell-bd">
        <div class="cell-list">
          <van-radio-group v-model="radio">
            <van-radio v-for="(item,index) in list" :key="index" :name="index">{{ item }}</van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="agree">
      <van-radio name="1">我已阅读并同意 《学员协议》</van-radio>
    </div>
    <div class="cell">
      <div class="cell-hd">
        <div class="cell-hd-title">课程列表</div>
      </div>
      <div class="cell-bd">
        <div class="cell-list">
          <div v-for="(item,index) in list" :key="index" class="cell-list-item">{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RadioGroup, Radio } from 'vant'
import PageMixin from '@/mixins/page'

export default {
  name: 'Membercard',
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  mixins: [PageMixin],
  data() {
    return {
      info: { 'fee_id': 10087, 'fee_cover': 'http://i01.wedots.cn/2019/07/27/a77fa34a486ea5aedd3057987cbee9a7.jpg?imageMogr2/thumbnail/750x360!/quality/75/format/png', 'fee_name': '收费11111', 'fee_type': 10, 'fee_type_name': '按课时收费', 'course_cate_id': 20060, 'times': '20.0', 'month': 0, 'price': '1000.00' },
      list: Array.from({ length: 6 }, (v, i) => i + 'nei'),
      radio: 1
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.card {
}
</style>

